.legal-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  font-family: Arial, sans-serif;
}

.legal-container h1 {
  color: #4a90e2;
  text-align: center;
  margin-bottom: 20px;
}

.legal-container h2 {
  color: #4a90e2;
  margin-top: 30px;
  margin-bottom: 15px;
}

.legal-container p, .legal-container ul {
  color: #333;
  line-height: 1.6;
  margin-bottom: 15px;
}

.legal-container ul {
  padding-left: 20px;
}
