.credits-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
}

.credits-title {
  text-align: center;
  color: var(--primary-color);
  margin-bottom: 20px;
}

.credits-intro {
  text-align: center;
  max-width: 800px;
  margin: 0 auto 40px;
  color: var(--light-text-color);
}

.credit-plans {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  margin-bottom: 60px;
}

.credit-plan {
  background-color: var(--white);
  border-radius: 8px;
  padding: 30px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.credit-plan:hover {
  transform: translateY(-5px);
}

.credit-plan.featured {
  border: 2px solid var(--secondary-color);
  position: relative;
}

.credit-plan.featured::before {
  content: 'Most Popular';
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--secondary-color);
  color: var(--white);
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.8em;
  font-weight: bold;
}

.credit-plan h2 {
  color: var(--primary-color);
  margin-bottom: 20px;
}

.credit-plan .price {
  font-size: 2.5em;
  font-weight: bold;
  color: var(--primary-color);
  margin-bottom: 10px;
}

.credit-plan .savings {
  color: var(--secondary-color);
  font-weight: bold;
  margin-bottom: 20px;
}

.credit-plan ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 30px;
}

.credit-plan li {
  margin-bottom: 10px;
  color: var(--light-text-color);
}

.purchase-button {
  background-color: var(--primary-color);
  color: var(--white);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.purchase-button:hover {
  background-color: #3a7bd5;
}

.credits-faq {
  max-width: 800px;
  margin: 0 auto;
}

.credits-faq h2 {
  text-align: center;
  color: var(--primary-color);
  margin-bottom: 30px;
}

.faq-item {
  margin-bottom: 30px;
}

.faq-item h3 {
  color: var(--primary-color);
  margin-bottom: 10px;
}

.faq-item p {
  color: var(--light-text-color);
}

.success {
  color: var(--success-color);
  text-align: center;
  margin-bottom: 20px;
}

.error {
  color: var(--error-color);
  text-align: center;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .credit-plans {
    grid-template-columns: 1fr;
  }
}
