.support-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 40px 20px;
  display: flex;
  justify-content: space-between;
}

.support-info {
  flex-basis: 45%;
  padding-right: 20px;
}

.support-form {
  flex-basis: 45%;
}

.support-info h2,
.support-form h2 {
  color: var(--primary-color);
  margin-bottom: 20px;
}

.support-info p {
  margin-bottom: 15px;
  line-height: 1.6;
}

.discord-link {
  display: inline-block;
  background-color: #7289da;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  margin-bottom: 15px;
  transition: background-color 0.3s ease;
}

.discord-link:hover {
  background-color: #5b73c7;
}

.support-form form {
  display: flex;
  flex-direction: column;
}

.support-form input,
.support-form textarea {
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.support-form textarea {
  height: 150px;
}

.support-form button {
  align-self: flex-start;
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.support-form button:hover {
  background-color: #3a7bd5;
}

.success {
  color: var(--success-color);
  margin-top: 15px;
}

.error {
  color: var(--error-color);
  margin-top: 15px;
}

@media (max-width: 768px) {
  .support-container {
    flex-direction: column;
  }

  .support-info,
  .support-form {
    flex-basis: 100%;
    padding-right: 0;
    margin-bottom: 30px;
  }
}
