.setup-guide-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  font-family: Arial, sans-serif;
}

.setup-guide-container h1 {
  color: #4a90e2;
  text-align: center;
  margin-bottom: 20px;
}

.intro {
  text-align: center;
  color: #666;
  margin-bottom: 40px;
}

.setup-steps {
  margin-bottom: 40px;
}

.step {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.step h2 {
  color: #4a90e2;
  margin-bottom: 10px;
}

.step p {
  color: #333;
  line-height: 1.6;
}

.step ul {
  margin-top: 10px;
  padding-left: 20px;
}

.additional-info {
  background-color: #e9ecef;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 40px;
}

.additional-info h2 {
  color: #4a90e2;
  margin-bottom: 10px;
}

.additional-info ul {
  padding-left: 20px;
}

.cta {
  text-align: center;
}

.cta-button {
  display: inline-block;
  background-color: #4a90e2;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #3a7bd5;
}

a {
  color: #4a90e2;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.video-card {
  margin-top: 2rem;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.video-card h3 {
  margin-bottom: 1rem;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
