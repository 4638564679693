:root {
    --primary-color: #4a90e2;
    --secondary-color: #f5a623;
    --background-color: #f0f2f5;
    --text-color: #333;
    --light-text-color: #666;
    --white: #ffffff;
    --error-color: #e74c3c;
    --success-color: #2ecc71;
  }
  
  body {
    margin: 0;
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--background-color);
    color: var(--text-color);
    margin-bottom: 60px; /* Adjust this value based on your footer's height */
  }
  
  .App {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    min-height: 100vh;
    position: relative;
    padding-bottom: 60px; /* Adjust this value based on your footer's height */
  }
  
  nav {
    background-color: var(--white);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
  }
  
  nav ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    justify-content: center;
  }
  
  nav ul li {
    margin: 0 15px;
  }
  
  nav ul li a, nav ul li button {
    text-decoration: none;
    color: var(--text-color);
    font-weight: 500;
    transition: color 0.3s ease;
  }
  
  nav ul li a:hover, nav ul li button:hover {
    color: var(--primary-color);
  }
  
  h1, h2, h3, h4 {
    color: var(--primary-color);
  }
  
  button {
    background-color: var(--primary-color);
    color: var(--white);
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #3a7bd5;
  }
  
  input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .error {
    color: var(--error-color);
  }
  
  .success {
    color: var(--success-color);
  }
  
  .card {
    background-color: var(--white);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .credit-plans {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
  }
  
  .credit-plan {
    background-color: var(--white);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
  }
  
  .credit-plan h3 {
    color: var(--secondary-color);
  }
  
  .hubspot-connections {
    list-style-type: none;
    padding: 0;
  }
  
  .hubspot-connections li {
    background-color: var(--white);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 15px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .hubspot-connections button {
    background-color: var(--error-color);
  }
  
  .hubspot-connections button:hover {
    background-color: #c0392b;
  }
  
  .logo-container {
    display: flex;
    justify-content: center;
    padding: 20px 0;
  }
  
  .logo {
    max-width: 200px;  /* Adjust this value to fit your logo size */
    height: auto;
  }
  
  .home-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .hero {
    text-align: center;
    padding: 50px 0;
    background-color: var(--primary-color);
    color: var(--white);
    border-radius: 8px;
    margin-bottom: 40px;
  }
  
  .hero h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  .tagline {
    font-size: 2em;
  }
  
  .features, .how-it-works, .pricing, .disclaimer, .cta {
    margin-bottom: 40px;
  }
  
  .feature-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .feature {
    background-color: var(--white);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .how-it-works ol {
    padding-left: 20px;
  }
  
  .how-it-works li {
    margin-bottom: 10px;
  }
  
  .cta-button {
    display: inline-block;
    background-color: var(--secondary-color);
    color: var(--white);
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #e09600;
  }
  
  .disclaimer {
    background-color: #f8f8f8;
    padding: 20px;
    border-radius: 8px;
    font-size: 0.9em;
  }
  
  .cta {
    text-align: center;
    background-color: var(--primary-color);
    color: var(--white);
    padding: 40px;
    border-radius: 8px;
  }
  
  .cta h2 {
    margin-bottom: 20px;
  }
  
  .pricing-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
  }
  
  .pricing-intro {
    text-align: center;
    max-width: 800px;
    margin: 0 auto 40px;
  }
  
  .pricing-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 30px;
    margin-bottom: 60px;
  }
  
  .pricing-plan {
    background-color: var(--white);
    border-radius: 8px;
    padding: 30px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .pricing-plan:hover {
    transform: translateY(-5px);
  }
  
  .pricing-plan.featured {
    border: 2px solid var(--primary-color);
  }
  
  .pricing-plan h2 {
    margin-bottom: 20px;
  }
  
  .pricing-plan .price {
    font-size: 2.5em;
    font-weight: bold;
    color: var(--primary-color);
    margin-bottom: 10px;
  }
  
  .pricing-plan .credits {
    font-size: 1.2em;
    margin-bottom: 20px;
  }
  
  .pricing-plan ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: 30px;
  }
  
  .pricing-plan li {
    margin-bottom: 10px;
  }
  
  .pricing-faq {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .faq-item {
    margin-bottom: 30px;
  }
  
  .faq-item h3 {
    margin-bottom: 10px;
  }
  
  .pricing-title {
    text-align: center;
    margin-bottom: 20px;
  }

footer {
  text-align: center;
  padding: 20px;
  background-color: var(--background-color);
  color: var(--text-color);
  position: absolute;
  bottom: 0;
  width: 100%;
}

footer a, footer Link {
  color: var(--primary-color);
  text-decoration: none;
  margin: 0 5px;
}

footer a:hover, footer Link:hover {
  text-decoration: underline;
}
