.why-us-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  font-family: Arial, sans-serif;
}

.why-us-container h1 {
  text-align: center;
  color: #4a90e2;
  margin-bottom: 40px;
}

.hero {
  background-color: #f8f9fa;
  padding: 40px;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 40px;
}

.hero h2 {
  color: #333;
  margin-bottom: 20px;
}

.benefits, .features, .use-cases, .testimonial, .cta {
  margin-bottom: 60px;
}

.benefit-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
}

.benefit {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.benefit i {
  font-size: 2em;
  color: #4a90e2;
  margin-bottom: 20px;
}

.features ul, .use-cases ul {
  padding-left: 20px;
  line-height: 1.6;
}

.testimonial {
  background-color: #f8f9fa;
  padding: 40px;
  border-radius: 10px;
  text-align: center;
}

.testimonial blockquote {
  font-style: italic;
  font-size: 1.2em;
  margin-bottom: 20px;
}

.cta {
  text-align: center;
  background-color: #4a90e2;
  color: white;
  padding: 40px;
  border-radius: 10px;
}

.cta h2 {
  margin-bottom: 20px;
}

.cta-button {
  display: inline-block;
  background-color: #f5a623;
  color: white;
  padding: 12px 24px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #e09600;
}

@media (max-width: 768px) {
  .benefit-grid {
    grid-template-columns: 1fr;
  }
}
