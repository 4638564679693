.home-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
  }
  
  /* Hero Section */
  .hero {
    text-align: center;
    padding: 80px 0;
    background: linear-gradient(135deg, #4a90e2, #0072b1); /* Updated gradient with more depth */
    color: white;
    border-radius: 15px; /* Softer, more modern border-radius */
    margin-bottom: 60px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* Added shadow for depth */
    position: relative;
    overflow: hidden;
  }
  
  .hero::before {
    content: '';
    position: absolute;
    top: -50px;
    right: -50px;
    width: 200px;
    height: 200px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 50%; /* Create a soft, circular light effect */
    z-index: 0;
  }
  
  .hero::after {
    content: '';
    position: absolute;
    bottom: -50px;
    left: -50px;
    width: 150px;
    height: 150px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 50%; /* A second circle for balance */
    z-index: 0;
  }
  
  .hero-content {
    position: relative;
    z-index: 2; /* Ensures the content is above the background shapes */
  }
  
  .hero h1 {
    font-size: 3em; /* Larger font for prominence */
    margin-bottom: 20px;
    color: #ff914d; /* Keep the orange for contrast */
    letter-spacing: 2px; /* Added letter spacing for a sleek look */
  }
  
  .tagline {
    font-size: 1.4em; /* Slightly larger tagline */
    margin-bottom: 40px;
    color: #fff;
  }
  
  .cta-button {
    display: inline-block;
    background-color: #f5a623;
    color: white;
    padding: 14px 28px; /* Increased padding for larger button */
    border-radius: 25px; /* More rounded corners for modern feel */
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.3s ease; /* Added transform */
  }
  
  .cta-button:hover {
    background-color: #e09600;
    transform: scale(1.05); /* Slight scale effect on hover */
  }
  
  /* General Sections */
  .features, .how-it-works, .pricing-preview, .cta {
    margin-bottom: 60px;
  }
  
  .features h2, .how-it-works h2, .pricing-preview h2, .cta h2 {
    text-align: center;
    margin-bottom: 30px;
    color: #333;
  }
  
  /* Feature Grid */
  .feature-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 30px;
  }
  
  .feature {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .feature i {
    font-size: 2em;
    color: #4a90e2;
    margin-bottom: 20px;
  }
  
  .steps {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 15px;
  }
  
  .step {
    flex-basis: calc(25% - 15px);
    text-align: center;
  }
  
  .step-number {
    display: inline-block;
    width: 40px;
    height: 40px;
    background-color: #4a90e2;
    color: white;
    border-radius: 50%;
    line-height: 40px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .pricing-preview, .cta {
    text-align: center;
    background-color: #f8f9fa;
    padding: 60px 0;
    border-radius: 10px;
  }
  
  .secondary-button {
    display: inline-block;
    background-color: white;
    color: #4a90e2;
    border: 2px solid #4a90e2;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    transition: all 0.3s ease;
  }
  
  .secondary-button:hover {
    background-color: #4a90e2;
    color: white;
  }
  
  .disclaimer {
    font-size: 0.9em;
    color: #666;
    text-align: center;
    margin-top: 60px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .feature-grid {
      grid-template-columns: 1fr;
    }
  
    .steps {
      flex-direction: column;
    }
  
    .step {
      margin-bottom: 20px;
    }
  }
  